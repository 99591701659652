import React from "react"
import Layout from "components/Layout"
import Button from "utils/Button"
import Seo from "utils/seo"
import Background from 'images/bg_404.svg'
import GameOver from 'images/game_over_404.svg'
import { PageContext } from "utils/context";

export default function Page404({ location }) {
  return (
    <PageContext.Provider
      value={{
        location: location,
      }}
    >
      <Seo pageName={"404 error page"} pageMeta={{description: "404 error page for Escape Studios' website"}}/>
      <Layout location={location} noFooter noPrefooter noHeaderFiller>
        <div className="bg-black relative">
          <div className="container mx-auto px-6 flex flex-col justify-center items-center 2xl:pt-28 pt-24 xl:pb-28 pb-20 min-h-screen">
            <div className="h-full w-full flex flex-col items-center xl:gap-12 gap-8 xl:max-w-xl sm:max-w-lg max-w-sm">
              <GameOver className="z-10"/>
              <Button
                to={'/'}
                className="z-10"
                arrowBoxClasses={`lg:group-hover:!bg-transparent`}
              >
                Restart
              </Button>
            </div>
            <Background className="absolute top-0 left-0 object-cover w-full h-full"/>
          </div>
        </div>
      </Layout>
    </PageContext.Provider>
  )
}